import Link from 'next/link';

const BlockIntro = ({
  title,
  descr,
  level,
  cta,
  center,
  light,
  showTitleUnderline = true,
  className,
}) => {
  const renderTitle = () => {
    const titleClassName = `block-intro__title ${
      showTitleUnderline ? 'block-intro__title--underline' : ''
    }`;

    switch (level) {
      case 1:
        return <h1 className={titleClassName}>{title}</h1>;
      case 3:
        return <h3 className={titleClassName}>{title}</h3>;
      default:
        return <h2 className={titleClassName}>{title}</h2>;
    }
  };

  return (
    <div
      className={`block-intro${center ? ' block-intro--centered' : ''}${
        light ? ' block-intro--light' : ''
      } ${className || ''}`}
    >
      {title && renderTitle()}

      {descr && <p className="block-intro__descr">{descr}</p>}

      {cta && cta.text && cta.href && (
        <Link href={cta.href}>
          <a className="button button--primary">{cta.text}</a>
        </Link>
      )}

      {cta && cta.text && cta.callback && (
        <button className="button button--primary" onClick={cta.callback}>
          {cta.text}
        </button>
      )}
    </div>
  );
};

export default BlockIntro;
