
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import {
  getProviders,
  getSession,
  signIn,
  signOut,
  useSession,
} from 'next-auth/react';
import useTranslation from 'next-translate/useTranslation';

import BlockIntro from 'components/block-intro';
import LinkSharePreview from 'components/link-share-preview';

import ROUTE from 'config/routes';

const SignIn = ({ providers = {}, nextRoute }) => {
  const { t } = useTranslation('common');

  const { data: session } = useSession();
  const router = useRouter();

  const [error, setError] = useState('');

  useEffect(() => {
    if (!router.query.error) return;

    let message = 'Something went wrong!';

    if (router.query.error === 'AccessDenied') {
      message = 'Invalid email address';
    }

    setError(message);
  }, [router.query.error]);

  const onSignin = (providerId) => {
    // TODO: validate if legit route format
    const callbackUrl = `${process.env.NEXT_PUBLIC_INTERNAL_BASE_URL}${
      nextRoute ? nextRoute : ROUTE.myCourses
    }`;

    signIn(providerId, {
      callbackUrl,
    });
  };

  return (
    <>
      <LinkSharePreview />

      <div className="signin">
        <div className="u-bg" />

        <div className="u-pattern left" />

        <div className="u-container">
          <div className="signin__form">
            <BlockIntro
              title={t('LOGIN.TITLE')}
              descr={t('LOGIN.DESCR')}
              center
            />

            {!session &&
              Object.values(providers).map((provider) => (
                <button
                  key={provider.name}
                  className="signin__provider"
                  onClick={() => onSignin(provider.id)}
                >
                  <img
                    src="/static/assets/icons/google.png"
                    width="20"
                    height="20"
                    alt="Google icon"
                  />
                  Sign in with {provider.name}
                </button>
              ))}

            {session && (
              <button className="signin__signout" onClick={() => signOut()}>
                Sign Out
              </button>
            )}

            {error && <div className="signin__error">{error}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

 const _getServerSideProps = async (context) => {
  const { query } = context;
  const session = await getSession(context);
  const providers = await getProviders();

  if (session) {
    return {
      redirect: {
        permanent: false,
        destination: '/',
      },
      props: {},
    };
  }

  return {
    props: {
      providers,
      nextRoute: query.next || '',
    },
  };
};

export default SignIn;


    export async function getServerSideProps(ctx) {
        let res = _getServerSideProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/auth/signin/index',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  