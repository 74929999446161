import Head from 'next/head';

const DEFAULTS = {
  title: 'TDL School - Your Course to Success',
  descr:
    'Develop existing or acquire new skills in the IT field to improve your career potential - be it in testing or other fields of software development.',
  url: process.env.NEXT_PUBLIC_BASE_URL,
  image: `${process.env.NEXT_PUBLIC_BASE_URL}/share/tdl-school.jpg`,
  imageAlt: 'TDL School',
  imageWidth: 1200,
  imageHeight: 630,
};

const LinkSharePreview = ({
  title,
  descr,
  url,
  image,
  imageAlt,
  imageWidth,
  imageHeight,
}) => (
  <Head>
    <title>{title ? `${title} - TDL School` : DEFAULTS.title}</title>
    <meta name="description" content={descr || DEFAULTS.descr} />

    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="TDL School" />
    <meta property="og:url" content={url || DEFAULTS.url} />

    <meta
      property="og:title"
      content={title ? `${title} - TDL School` : DEFAULTS.title}
    />
    <meta property="og:description" content={descr || DEFAULTS.descr} />
    <meta property="og:image" content={image || DEFAULTS.image} />
    <meta
      property="og:image:width"
      content={imageWidth || DEFAULTS.imageWidth}
    />
    <meta
      property="og:image:height"
      content={imageHeight || DEFAULTS.imageHeight}
    />
    <meta property="og:image:alt" content={imageAlt || DEFAULTS.imageAlt} />
  </Head>
);

export default LinkSharePreview;
